import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "margin-top30px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_pagination = _resolveComponent("list-pagination")
  const _component_cus_button = _resolveComponent("cus-button")
  const _component_enlarge_image = _resolveComponent("enlarge-image")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_dialog_shell, {
      title: _ctx.$wordList.ProperAllTextDoorLogs,
      "footer-type": "customize",
      width: "1200px",
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
    }, {
      footer: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode(_component_cus_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$wordList.TabelFootCancel), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_list_pagination, {
          headers: _ctx.headers,
          requestData: _ctx.listRequestData,
          update: _ctx.updateToList,
          showCheck: false,
          action: false
        }, {
          SPicUrlSlot: _withCtx((data) => [
            _createVNode("li", {
              class: "pre-content",
              style: {"cursor":"pointer"},
              onClick: ($event: any) => (_ctx.showEnlargeImage(data.scope.PicUrl))
            }, [
              _createVNode("img", {
                class: "capture-img",
                src: data.scope.SPicUrl,
                ref: _ctx.setAllImg
              }, null, 8, ["src"])
            ], 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["headers", "requestData", "update"]),
        (_ctx.isShowEnlargeImage)
          ? (_openBlock(), _createBlock(_component_enlarge_image, {
              key: 0,
              picAddress: _ctx.picAddress,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowEnlargeImage=false))
            }, null, 8, ["picAddress"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}